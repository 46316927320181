<template>
<div class="deposit-container">
<ConfitmModal v-if="modal" @close="closeModal" :data.sync="data" :type="state.userInfo.towStepAuthenticationType"/>
    <p class="title">
        برداشت کوین
    </p>
    <div class="info-container">
        <div class="coin-info">
            <div>
                <p class="label">
                    نوع کوین
                </p>
                <div class="selected-container" @click="drop = !drop">
                    <img class="list-img" :src="'/coins/'+ $route.params.coin + '.png'">
                    <span> {{$coinLabel[$route.params.coin]}} </span>
                    <img class="dropIcon" src="@/assets/dropIcon.svg" alt="">
                    <div class="drop-down" v-if="drop">
                        <div @click="selectWallet(wallet)" v-for="(wallet,index) in wallets" :key="index">
                            <img class="list-img" :src="'/coins/'+ wallet.relatedCoin + '.png'">
                            <span> {{$coinLabel[wallet.relatedCoin]}} </span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <p class="label">
                    مقدار
                </p>
                <div class="selected-conteiner">
                    <input @input="data.amount = $toLocal(data.amount,$decimal[$route.params.coin])" class="address-input" type="text" v-model="data.amount">
                </div>
            </div>
            <div>
                <p class="label">
                    آدرس کیف پول
                </p>
                <div class="selected-conteiner">
                    <input class="address-input" type="text" v-model="data.destinationWalletAddress">
                </div>
            </div>
            <button @click.prevent="openModal" :disabled="disable" class="normal-btn">
                 {{disable ? disable : 'برداشت'}}
            </button>
        </div>
        <div class="guide-container">
            <div>
                راهنمای برداشت
            </div>
            <div class="warning-container">
                <img src="@/assets/warning.svg" alt="">
                <span>
                    در وارد کردن آدرس کیف پول خود دقت فرمایید و از آدرس کیف پول معتبر بیت کوین استفاده کنید. مسيولیت و عواقب آدرس کیف پول نامعتبر به عهده خریدار میباشد .
                </span>
            </div>
            <div class="coininfo">
                <span>  کارمزد انتقال در شبکه {{$coinLabel[$route.params.coin]}}: </span>
                <span> {{selectCoin.transferFee}} </span>
            </div>
            <div class="coininfo">
                <span> حداقل میزان برداشت از {{$coinLabel[$route.params.coin]}}: </span>
                <span> {{selectCoin.minWithdrawAmount}} </span>
            </div>
            <div class="coininfo">
                <span>  حداکثر میزان برداشت از {{$coinLabel[$route.params.coin]}}: </span>
                <span> {{$toLocal(selectCoin.maxWithdrawAmount)}} </span>
            </div>
            <div class="coininfo">
                <span>  موجودی:</span>
                <span> {{$toLocal(credit)}} </span>
            </div>
        </div>
    </div>
    <div class="history-container">
        <p>
            تاریخچه برداشت کوین
        </p>
        <div class="table-contaner">
            <span v-if="tableContents.length < 1" class="no-content">
                محتوایی جهت نمایش وجود ندارد
            </span>
            <perfect-scrollbar v-else @ps-y-reach-end="getWithDrawal">
                <table>
                    <tr class="tr-head">
                        <td class="date">
                            زمان
                        </td>
                        <td>
                            رمزارز
                        </td>
                        <td>
                            مقدار درخواستی
                        </td>
                        <!-- <td class="address">
                            کارمزد شبکه
                        </td> -->
                        <td class="destination">
                            آدرس مقصد
                        </td>
                        <td>
                            وضعیت
                        </td>
                        <td class="trnasactionId">
                            شناسه تراکنش
                        </td>
                    </tr>
                    <tr class="tr-body" v-for="(item , index) in tableContents" :key="index">
                        <td class="date">
                            {{$G2J(item.createdAtDateTime)}}
                        </td>
                        <td>
                            {{$coinLabel[$route.params.coin]}}
                        </td>
                        <td>
                            {{$toLocal(item.amount,$decimal[$route.params.coin])}}
                        </td>
                        <!-- <td class="address">
                            5%
                        </td> -->
                        <td class="destination">
                            {{item.destinationWalletAddress}}
                        </td>
                        <td v-if="item.withdrawalRequestStatus.includes(['FINISHED','ACCEPTED'])" class="success">
                            موفق
                        </td>
                        <td v-if="item.withdrawalRequestStatus.includes('CANCELLED')" class="fail">
                            نا موفق
                        </td>
                        <td v-if="item.withdrawalRequestStatus.includes('RAISED')" class="pending">
                            در حال بررسی
                        <td class="trnasactionId">
                            مشاهده جزییات
                        </td>
                    </tr>
                </table>
            </perfect-scrollbar>
        </div>
    </div>
</div>
</template>

<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import ConfitmModal from './ConfirmModal.vue'
export default {
    name: 'CoinDeposit',
    components:{
        ConfitmModal,
        PerfectScrollbar
    },
    data() {
        return {
            data:{
                amount:'',
                relatedCoin:this.$route.params.coin,
                destinationWalletAddress:null,
                tokenType:null,
                walletType:'P2P',
                code:'',
            },
            depositeCode:'',
            qrCode:'',
            drop: false,
            wallets:[],
            selectCoin:[],
            credit:0,
            modal:false,
            tableContents:[],
            page:0,
            lastPage:1,
        }
    },
    computed:{
        disable(){
            let errors =''
            if(!this.$S2N(this.data.amount)){
                errors='مقدار را وارد کنید'
            } else if (this.credit < this.$S2N(this.data.amount) ){
                errors='موجودی ناکافی'
            } else if(!this.data.destinationWalletAddress){
                errors='آدرس کیف پول را وارد کنید'
            } else if(this.data.amount > this.selectCoin.maxWithdrawAmount){
                errors='بیشتر از حداکثر مجاز'
            } else if(this.data.amount < this.selectCoin.minWithdrawAmount){
                errors='کمتر از حداکثر مجاز'
            } else {
                errors=false;
            }
            return errors
        }
    },
    methods: {
            async getWithDrawal(){
                if (this.page < this.lastPage) {
                    this.page++ ;
                    const res = await this.$axios.get('/wallets/withdrawal-requests', {
                        params: {
                            size: 10,
                            page: this.page
                        }
                    })
                    if (res) {
                        this.lastPage = res.totalPages
                        let content = res.content.filter(a => a.relatedCoin == this.$route.params.coin)
                        this.tableContents = [...this.tableContents, ...content] //for scroll loading
                        // this.tableContents = res.content //for pagination
                    }
                }
            },
            openModal(){
                this.modal=true
            },
            closeModal(){
                this.modal=false
                this.data={
                    relatedCoin:this.$route.params.coin,
                    amount:null,
                    destinationWalletAddress:null,
                    tokenType:'',
                    code:'',
                    tag: "string",
                }
                this.getBalance();
            },
            async getWallets() {
                    this.state.loading=true
                    const res = await this.$axios.get('/wallets/customer-addresses')
                    let entireRes = Object.entries(res)
                    this.sortWallets(entireRes);
            },
            sortWallets(e){
                const sortCoins = ['TOMAN', 'TETHER', 'BITCOIN', 'ETHEREUM', 'BITCOIN_CASH', 'LITE_COIN', 'RIPPLE', 'CLASSIC_ETHEREUM', 'DASH', 'DOGE_COIN'];
                e.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
                this.wallets = e.map(a => {
                        let relatedCoin = a[0]
                        if(a.relatedCoin) {
                            a=a[1]
                        }else{
                            a=a[1]
                            a.relatedCoin=relatedCoin
                        }
                        return a
                        })
                    this.wallets=this.wallets.filter(wallet =>  wallet.relatedCoin !== 'TOMAN')
                    this.wallets.sort((a,b) => b.credit - a.credit)
                    this.credit = this.wallets.filter(a => a.relatedCoin == this.$route.params.coin)[0].credit
            },
            async getData() {
                const res = await this.$axios.get('/coins')
                this.selectCoin = res.content.filter(a => a.coin==this.$route.params.coin)[0]
                this.data.tokenType=this.selectCoin.tokenType
                this.getSelectedWallet()
            },
            async getSelectedWallet(){
                this.state.loading=true
                const [res,err] = await this.$http.get(`/wallets/customer/wallet-address?walletType=P2P&tokenType=${this.selectCoin.tokenType}&relatedCoin=${this.selectCoin.coin}`)
                    if(res){
                        console.log('wallet Exist');
                    }
                    if(err){
                        this.$router.push({name:'Wallet'})
                    }
            },
            selectWallet(wallet){
                this.$router.push('/dashboard/deposit-coin/'+wallet.relatedCoin)
                this.getData();

            }
    },
    mounted() {
        this.getWallets();
        this.getData();
        this.getWithDrawal();
        
    },


    created:function(){
        window.addEventListener("click" , event => {
            if(!event.target.closest('.selected-container')){
                this.drop=false;
            }
        })
    }

}
</script>

<style lang="scss" scoped>
@import '../../../Styles/Wallet.scss';
.coininfo{
    width: 50% !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: flex-start;
    justify-self: self-start;
}
.qrcode{
    position: absolute;
    width: 124px;
    height: 124px;
    top: 20px;
    left: -10px;
}
.coin-info{
    position: relative;
}

.limit-container{
    cursor: text !important; 
}
.drop-down{
    z-index: 3;
    max-height: 250px;
    overflow-y: scroll;
}
.selected-conteiner{
    display: flex;
    flex-direction: row;
    width: 100%;
    background: #ECEEEF;
    border-radius: 8px;
    align-items: center;
    input{
        flex: 9;
    }
    svg{
        cursor: pointer;
        transition: 300ms;
        flex: 1;
        width: 20px;
        height: 20px;
        fill: var(--grey);
        &:hover{
            fill: var(--primary-color) !important;
        }
    }
}
</style>
